/*.race-container {
}*/

.abort {
  position: absolute;
  /*margin-left: -15px;*/
  margin: 2px;
  z-index: 7;
  opacity: 0.5;
}

.start {
  position: absolute;
  /*margin-left: -15px;*/
  margin: 2px;
  /*padding-left: 33%;*/
  z-index: 7;
  opacity: 0.5;
}

.finish {
  position: absolute;
  margin: 2px;
  margin-left: -30px;
  padding-left: 100%;
  z-index: 6;
  opacity: 0.5;
}

.race-container .body {
  border: 1px solid #333; /* #464545; */
  border-radius: 4px;
  overflow: hidden;
}

.race-container .inner-body {
  position: relative;
  height: 32px;
  background-color: #ccc;
  box-sizing: border-box;
}

.inner-body .racer {
  position: absolute;
  top: 3px;
  width: 100%;
  margin-left: -32px;
  /*padding-left: 73%;*/
  z-index: 10;
}

.inner-body .pnl {
  position: absolute;
  height: 32px;
  /*width: 73%;*/
  z-index: 8;
  border-right: 5px solid #ffd700;
  box-sizing: border-box;
}

.inner-body .stop {
  position: absolute;
  height: 32px;
  /*width: 0%;*/
  z-index: 8;
  border-right: 5px solid #c41e3a;
}

.inner-body .loss {
  position: absolute;
  height: 32px;
  width: 100%;
  z-index: 2;
  background-color: #cd5c5c;
  text-align: center;
  line-height: 200%;
}

.inner-body .slippage {
  position: absolute;
  height: 32px;
  text-align: center;
  line-height: 200%;
  z-index: 3;
}

.inner-body .slippage.neg {
  background-color: #f0ed95;
}

.inner-body .slippage.pos {
  background-color: #fc8eac;
}

.inner-body .profit {
  position: absolute;
  height: 32px;
  z-index: 4;
  background-color: #ace1af;
  text-align: center;
  line-height: 200%;
}

.inner-body .timer {
  position: absolute;
  bottom: 0;
  height: 20px;
  /*width: 65%;*/
  z-index: 1;
  background-color: #f3e5ab; /*#99badd;*/
}

.race-container .head,
.race-container .footer {
  overflow-wrap: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px;
}

.footer .loss {
  color: #c41e3a;
}

.footer .profit {
  color: #8fbc8f;
}

.car-l {
  position: absolute;
  width: 100%;
  top: 3px;
  margin-left: -26px;
  /* padding-left: 75%; */
  z-index: 10;
  line-height: 0;
}

.car-p {
  position: absolute;
  width: 100%;
  top: 27px;
  margin-left: -26px;
  /* padding-left: 0%; */
  z-index: 10;
  line-height: 0;
}

.car-t {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 51px;
  margin-left: -26px;
  /* padding-left: 50%; */
  z-index: 10;
  line-height: 0;
}

.race-container .body-b {
  border: 4px solid #98817b;
  border-radius: 4px;
  background-image: url('../svg/race-track4.svg');
  background-repeat: repeat-x;
  overflow: hidden;
}

.race-container .inner-body-b {
  position: relative;
  height: 70px;
  margin-left: 30px;
}

.start-line {
  position: absolute;
  height: 100%;
  width: 6px;
  margin-left: -6px;
  z-index: 2;
  background-image: url('../svg/race-line.svg');
  background-repeat: no-repeat;
  opacity: 0.5;
  box-sizing: border-box;
}

.finish-line {
  position: absolute;
  height: 100%;
  top: 0px;
  right: 0px;
  width: 6px;
  /* margin-left: -6px; */
  z-index: 2;
  background-image: url('../svg/race-line.svg');
  background-repeat: no-repeat;
  opacity: 0.5;
}
