html {
  /* base size for rem */
  font-size: 16px;
}

/* fix for not zooming on input-focus */
body {
  font-size: 16px;
}
input,
select {
  font-size: 100%;
}

/* sticky footer start */
.flex-body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.flex-content {
  flex: 1 0 auto;
}
.flex-footer {
  flex-shrink: 0;
  margin-top: 100px;
}
/* sticky footer end */

/* growl fix */
.input-group .form-control {
  z-index: inherit;
}

.clickable {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}
.wrap {
  white-space: normal !important;
}

.middle {
  vertical-align: middle !important;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}

.gap-left-5 {
  margin-left: 5px;
}
.gap-left-10 {
  margin-left: 10px;
}
.gap-right-5 {
  margin-right: 5px;
}
.gap-right-10 {
  margin-right: 10px;
}
.gap-top-5 {
  margin-top: 5px;
}
.gap-top-10 {
  margin-top: 10px;
}
.gap-top-20 {
  margin-top: 20px;
}
.gap-top-30 {
  margin-top: 30px;
}
.gap-bottom-5 {
  margin-bottom: 5px;
}
.gap-bottom-10 {
  margin-bottom: 10px;
}
.gap-bottom-15 {
  margin-bottom: 15px;
}
.gap-bottom-20 {
  margin-bottom: 20px;
}
.gap-bottom-30 {
  margin-bottom: 30px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.pr-20 {
  padding-right: 20px;
}

.btn-xs.btn-backtest {
  width: 65px;
}

.control-label-custom {
  text-align: right;
  font-weight: bold;
  padding-top: 7px;
  margin-bottom: 0;
}

.control-label-value {
  font-weight: normal;
  padding-top: 10px;
}

.section > p {
  font-size: 12px;
  font-weight: 700;
  color: #959595;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.section > hr {
  margin-bottom: 5px;
}

.form-horizontal .checkbox {
  padding-top: 2px;
}

.paper-text {
  color: #fff;
  background-color: #701111;
}

.paper-text:hover {
  background-color: #4b0e0e;
}

.glyphicon-arrow-up.seasonal {
  transform: rotate(60deg);
}

.glyphicon-arrow-down.seasonal {
  transform: rotate(-60deg);
}

.glyphicon-arrow-up.trend {
  color: #1ca9c9;
  transform: rotate(45deg);
}

.glyphicon-arrow-up.trend.grey {
  color: #808080;
}

.glyphicon-arrow-up.trend-light {
  transform: rotate(80deg);
}

.glyphicon-arrow-up.trend-strong {
  transform: rotate(30deg);
}

.glyphicon-arrow-up.trend-light.long,
.glyphicon-arrow-up.trend.long,
.glyphicon-arrow-up.seasonal.long,
.glyphicon-arrow-up.trend-strong.long {
  color: #5cb85c;
}

.glyphicon-arrow-up.trend-light.short,
.glyphicon-arrow-up.trend.short,
.glyphicon-arrow-up.seasonal.short,
.glyphicon-arrow-up.trend-strong.short {
  color: indianred;
}

.glyphicon-arrow-down.trend {
  color: #ff9f00;
  transform: rotate(-45deg);
}

.glyphicon-arrow-down.trend.grey {
  color: #808080;
}

.glyphicon-arrow-down.trend-light {
  transform: rotate(-80deg);
}

.glyphicon-arrow-down.trend-strong {
  transform: rotate(-30deg);
}

.glyphicon-arrow-down.trend-light.long,
.glyphicon-arrow-down.trend.long,
.glyphicon-arrow-down.seasonal.long,
.glyphicon-arrow-down.trend-strong.long {
  color: indianred;
}

.glyphicon-arrow-down.trend-light.short,
.glyphicon-arrow-down.trend.short,
.glyphicon-arrow-down.seasonal.short,
.glyphicon-arrow-down.trend-strong.short {
  color: #5cb85c;
}

div.online {
  background-color: #32cd32;
}

.pending {
  color: #ffea00;
}

.offline {
  color: #dc143c;
}

.unknown-status {
  color: #959595;
}

.status {
  display: inline;
}

table > tbody > tr > td {
  white-space: nowrap;
}

.scrolling table {
  height: 100%;
  table-layout: inherit;
}
.outer {
  position: relative;
}
.inner {
  overflow-x: auto;
  overflow-y: visible;
  margin-left: 130px;
}
.scrolling th {
  position: absolute;
  left: 0;
  width: 130px;
}
.scrolling table > thead > tr > th {
  border: 0px;
}
.scrolling table > thead > tr > td {
  font-weight: bold;
  border: 0px;
}
.scrolling table > tbody {
  border: 0px;
}
.scrolling table > tbody > tr > th {
  font-weight: normal;
  /*border-right: 1px solid #ddd;*/
}
.scrolling table > tfoot > tr > td {
  white-space: nowrap;
}
.table {
  margin-bottom: 10px;
}
.table th.bold {
  font-weight: bold;
}

.paper-banner {
  font-size: 10px;
  padding: 2px;
  text-align: center;
}

nav .live {
  font-size: 10px;
  background: #5cb85c;
  padding: 2px;
  text-align: center;
}

td.grey {
  color: #959595;
}

.simulated,
tr.simulated > th,
tr.simulated > td {
  color: #979797;
  /*font-style: italic;*/
}

.backtest {
  display: flex;
  flex-grow: 0;
  margin-bottom: 10px;
  flex-wrap: nowrap;
}

.backtest .grow {
  flex-grow: 1;
}

.backtest div {
  margin-top: 5px;
  margin-right: 5px;
}

.radio-inline.space {
  padding-top: 16px;
}

.info.light {
  opacity: 0.7;
}

.breadcrumb {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 1200px) {
  .col-lg-offset-right-8 {
    margin-right: 66.66666667%;
  }
}

@media (min-width: 768px) {
  .text-right-sm {
    text-align: right;
  }
}

.navbar {
  margin-bottom: 0px;
}

.panel-lagging {
  border-color: #2f4f4f;
}

.panel-lagging > .panel-heading {
  border-color: #2f4f4f;
  background-color: #2f4f4f;
}

.panel-lagging .panel-body {
  /*border-top-color: #2f4f4f;*/
  border-top: 1px solid #2f4f4f !important;
}

.btn-default.btn-lagging {
  background-color: #2f4f4f;
  border-color: #2f4f4f;
  padding: 1px 5px;
  border-width: 2px;
}

.btn-lagging.active,
.btn-lagging:hover {
  color: #ffffff;
  background-color: #1c2f2f;
  background-image: none;
  border-color: #2f3e4f;
}

.panel-leading {
  border-color: #2f464f;
}

.panel-leading > .panel-heading {
  border-color: #2f464f;
  background-color: #2f464f;
}

.panel-leading .panel-body {
  /*border-top-color: #2f4f4f;*/
  border-top: 1px solid #2f464f !important;
}

.panel-leading a:hover {
  color: #ffffff;
}

.fa-xs {
  font-size: 16px;
}

.table th.vborder,
td.vborder {
  border-left: 1px solid #ddd !important;
}

.flex-container {
  display: flex;
}

.light .control-label {
  padding-top: 6px;
  margin-bottom: 0;
  text-align: right;
}

.light .control-label-value {
  padding-top: 6px;
  margin-bottom: 0;
}

.react-switch--sm {
  margin-top: 3px;
  margin-bottom: 3px;
}

.datepicker-fix .input-group-addon {
  border: 0 !important;
}

.round {
  border-radius: 50%;
}

.title-small {
  font-size: small;
}

.custom-label {
  font-size: 11.25px;
  margin-left: 3px;
  background-color: #2c2c2c;
  padding-top: 0.3rem;
}

.width_100 {
  width: 100%;
}

.overlapping {
  opacity: 0.5;
}

hr.std {
  margin: 10px 0 10px 0;
}
