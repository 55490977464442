ul li.tab-disabled > a {
  color: rgb(104, 104, 104);
  background-color: rgb(58, 58, 58);
}

/*
ul li.tab-disabled > a:hover {
  background-color: rgb(172, 169, 169);
}
*/

ul .active.tab-disabled > a {
  color: #fff;
  background-color: rgb(58, 58, 58);
}

ul .active.tab-disabled > a:focus {
  color: #fff;
  background-color: rgb(58, 58, 58);
}

.custom_timepicker {
  color: #fff;
}
.custom_timepicker input {
  color: #fff;
}
.custom_timepicker svg {
  stroke: #00bc8c; /* $brand-success; */
}
.custom_timepicker .react-time-picker__wrapper {
  padding: 3px;
  border-radius: 3px;
  line-height: 1.8rem;
}

.navbar-nav > li > a {
  line-height: inherit;
}
